.nav-bg {
    background-color: #f4f4f4;
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 2;
}

.main-nav {
    width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.div {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat';
}

.aurelius-llc {
    color: #3f4654;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
}

.contact-btn {
    background-color: #4faae8;
    border-radius: 60px;
    color: white;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    height: 40px;
}