.trackr-title {
font-size: 70px;
color: #131313;
font-weight: 600;
}

.android-coming-soon-text {
  text-align: center;
  position: absolute;
  color: #FFFFFF;
  font-size: .8rem;
  font-weight: 900;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-right: 10px;
}

.coming-soon {
  font-size: 14px;
  color: #989898;
}

.contact-btn:hover {
  background-color: #2360DB;
  cursor: pointer;
}

.text-container {
    font-family: "Montserrat", Helvetica;
}

.trackr-subtext-one {
    font-weight: 600;
    color: #3F4654;
    font-size: 35px;
    margin-top: 25px;
}

.trackr-subtext-two {
    margin-top: 30px;
    width: 390px;
    font-weight: 500;
    font-size: 20px;
    color: #354654;
}

.trackr-subtext-end {
    margin-top: 25px;
    width: 250px;
    font-weight: 500;
    font-size: 20px;
    color: #354654;
}


.app-store-icon {
  width: 135px;
}


.main-elements-div {
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    margin-top: 150px;
}


.app-download-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.download-store-icon {
  flex: 1;
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
}

.select-device-text {
  padding-top: 20px;
  font-weight: 900;
  font-size: 20px;
  color: #eeeeee;
  text-align: center;
  font-family: "Lato", Helvetica, sans-serif;
}

.app-download-icons-container {
  width: 400px;
  margin: none;
  margin-top: 25px;
  padding: 0px 0px 20px 0px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .9);
}

.screens-container {
    height: 500px;
    width: 500px;
    align-items: center;
}

.screens-container-sm {
  display: none;
}

.app-screenshot, .app-img {
    height: 50%;
    width: 50%;
    z-index: 1;
}

.app-screenshot {
    padding-right: 10px;
}

.rectangle {
    background-color: #6498ff50;
    height: 520px;
    width: 520px;
    border-radius: 50px 0px 0px 50px;
    right: 0;
    position: absolute;
    z-index: 0;
}

.overlap-group {
    align-items: center;
    display: flex;
}

.line-svg {
    height: 100%;
    width: 100%;
    position: relative;
}

.progress-line {
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 1000px) {


  .android-coming-soon-text { 
    font-size: .6rem;
    margin-right: 0;
  }

  .text-container {
    text-align: center;
  }

  .coming-soon {
    white-space: nowrap;
  }
  .main-elements-div {
    justify-content: center;
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .trackr-subtext-two {
    width: 300px;
  }
  .screens-container {
    display: none;
  }
  .screens-container-sm {
    display: block;
    margin: auto;
    transform: translateX(-5px);
    margin-top: 30px;
    width: 90%;
    height: auto;
  }
  .screens-container-sm .rectangle {
    display: none;
    height: auto;
  }
  .line-svg {
    margin-top: 50px;
  }
  
  .select-device-text {
    font-size: 22px;
  }
}


@media only screen and (min-width: 1000px) {
  .left-side-container {
    margin-left: 7%;
  }

  .show-on-mobile {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {

  .app-store-icon {
    width: 100%;
  }

  .trackr-subtext-end {
    font-size: 25px;
  }

  .app-store-icon {
    width: 100%;
  }

  .never-struggle-alone-img {
    width: 500px;
  }

  .canvas-container {
    display: "block";
    width: 100%;
    height: initial;
  }

  #canvas {
    width: 500px;
  }

  .hide-on-mobile {
    display: none;
  }

  .app-download-icons-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .app-download-icons-container {
    margin: auto;
    width: 70%;
  }

  .main-elements-div {
    display: block;
    justify-content: center;
    width: calc(100% - 40px);
    height: initial;
  }

  .call-to-action-container {
    margin-top: 150px;
    margin-left: 0;
  }

  .coming-soon {
    white-space: nowrap;
  }

  .trackr-subtext-two {
    width: 300px;
  }
  .screens-container {
    display: none;
  }
  .screens-container-sm {
    display: block;
    margin: auto;
    transform: translateX(-5px);
    margin-top: 30px;
    width: 90%;
    height: auto;
  }
  .screens-container-sm .rectangle {
    display: none;
    height: auto;
  }
  .line-svg {
    margin-top: 50px;
  }

  .select-device-text {
    font-size: 22px;
  }
}


@media only screen and (max-width: 660px) {

  .aurelius-llc {
    font-size: 18px;
  }

  .contact-btn {
    font-size: 16px;
  }

  .trackr-subtext-one {
    font-weight: 900;
    font-size: 18px;
  }

  .text-container {
    margin: auto;
    width: 70%;
    text-align: left;
  }

  .trackr-subtext-end {
    font-size: 25px;
  }

  .never-struggle-alone-img {
    width: 90%;
  }

  #canvas {
    width: 100%;
  }

  .app-download-icons-container {
    width: 100%;
  }
}