@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600');

.App {
  font-family: 'Montserrat';
}

.agreement-iframe {
  display: block;
  margin: auto;
  width: 90%;
  border: 0;
}
